import { useEffect } from 'react';

export default function getError(fieldName, { touched, errors }) {
  const errorStatus = {
    status: false,
    msg: '',
  };

  const hasError = !!(touched[fieldName] && errors[fieldName]);

  if (hasError) {
    errorStatus.status = true;
    errorStatus.msg = errors[fieldName];
  }

  return errorStatus;
}

export function getArrayFieldError(fieldName, { touched, errors, index }) {
  const errorStatus = {
    status: false,
    msg: '',
  };

  const isTouched = touched?.[index]?.[fieldName] || false;
  const fieldError = errors?.[index]?.[fieldName]?.props?.id || null;

  const hasError = !!(isTouched && fieldError);

  if (hasError) {
    errorStatus.status = true;
    errorStatus.msg = fieldError;
  }

  return errorStatus;
}

const getFieldErrorNames = (formikErrors) => {
  const transformObjectToDotNotation = (obj, prefix = '', result = []) => {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (!value) return;

      const nextKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === 'object') {
        transformObjectToDotNotation(value, nextKey, result);
      } else {
        result.push(nextKey);
      }
    });

    return result;
  };

  return transformObjectToDotNotation(formikErrors);
};

export const ScrollToFieldError = ({
  formik,
  scrollBehavior = { behavior: 'smooth', block: 'center' },
}) => {
  const { submitCount, isValid, errors } = formik;

  useEffect(() => {
    if (isValid) return;

    const fieldErrorNames = getFieldErrorNames(errors);

    if (fieldErrorNames.length <= 0) return;

    let elementNameToScrollTo = fieldErrorNames[0];

    // Check if the element name matches the pattern of a nested field (e.g., "field.0.subfield")
    // If it does, convert the dot notation to bracket notation for array indices (e.g., "field[0].subfield")
    if (/(\w+)\.(\d+)\.(\w+)/.test(elementNameToScrollTo)) {
      elementNameToScrollTo = elementNameToScrollTo.replace(
        /(\w+)\.(\d+)/g,
        '$1[$2]'
      );
    }

    const element = document.getElementsByName(elementNameToScrollTo)[0];

    if (!element) return;

    // Scroll to first known error into view
    element.scrollIntoView(scrollBehavior);

    // Formik doesn't (yet) provide a callback for a client-failed submission,
    // thus why this is implemented through a hook that listens to changes on
    // the submit count.
  }, [submitCount]);
};
