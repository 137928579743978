import { createStyles } from '@mantine/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import Link from 'next/link';
import { t, Trans } from '@lingui/macro';
import {
  Button,
  TextInput,
  PasswordInput,
  Text,
  UnstyledButton,
} from '@akin/ui-lib';
import getError from '../lib/formik';
import { login, resetDefaultPassword } from '../services/auth';
import { getAuthLayout as AuthLayout } from '../components/layouts';
import useAuth from '../hooks/useAuth';
import PAGE_ROUTES from '../constants/pageRoutes';
import { showError, showSuccess } from '../lib/notifications';
import PageTitle from '../components/layouts/PageTitle';
import { acceptInvite } from '../services/invite';
import useURLParams from '../hooks/useURLParams';
import useSpaceSettings from '../hooks/useSpaceSettings';
import AuthPageWrapper from '../components/auth/AuthPageWrapper';
import InputLabel from '../components/auth/InputLabel';
import { getLandingPageRoute } from '../features/permissions/utils';

const useStyles = createStyles(() => ({
  cardContainer: {
    maxWidth: 400,
    margin: '0 auto',
  },
  linkButton: {
    marginLeft: 5,
    fontSize: 12,
    color: '#9984D4',
    fontWeight: 500,
    '& a': {
      textDecoration: 'none',
      color: 'inherit',
      fontSize: 'inherit',
    },
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required(<Trans>Email is a required field</Trans>),
  password: Yup.string().required(<Trans>Password is a required field</Trans>),
  newPassword: Yup.string().when('isPasswordResetRequired', {
    is: true,
    then: Yup.string()
      .required(<Trans>New password is required</Trans>)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\[\]\{\}.|\\\"$';,:;=/\(\),\-\+\<\>\?\_])(?=.{8,})/ /* eslint-disable-line */,
        t`Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and
            One Special Case Character`
      ),
    otherwise: Yup.string(),
  }),
});

export default function Login() {
  const { classes } = useStyles();
  const auth = useAuth();
  const { router, getURLparams } = useURLParams();
  const query = getURLparams();
  const { switchToSpace } = useSpaceSettings();

  const [isLoading, setIsLoading] = useState(false);

  const redirectToSignup = () => {
    let queryParams = {};

    // Set query params for invite space flow
    if (query?.inviteToken) {
      queryParams = {
        inviteToken: query?.inviteToken,
        inviteeEmail: query?.inviteeEmail,
      };
    }

    // Set query params for invite collaboration flow
    // if (query?.collaborationInvite) {
    //   queryParams = {
    //     collaborationInvite: true,
    //     return_url: query.return_url,
    //     inviteeEmail: query?.inviteeEmail,
    //   };
    // }

    router.push({
      pathname: PAGE_ROUTES.signup,
      query: { ...queryParams },
    });
  };

  const handleSuccessfulLogin = async (loginData) => {
    let selectedSpace = null;
    let userData = { ...loginData };

    if (loginData?.spaces?.length > 0) {
      // Find brand space in spaces list and set it
      // as default selected space
      selectedSpace = loginData.spaces.find(
        (space) => space?.ai_subtenant_code === '*'
      );

      // If brand space is not found, set the first space
      // in spaces list as default selected space.
      if (!selectedSpace) {
        selectedSpace = { ...loginData.spaces[0] };
      }
    }

    // If inviteToken param is present
    // accept space invite and login to invited space
    if (query?.inviteToken) {
      try {
        const inviteResponse = await acceptInvite({
          token: query.inviteToken,
        });

        userData = {
          ...loginData,
          spaces: [...loginData.spaces, inviteResponse.space],
          permissions: inviteResponse?.permissions && {
            ...inviteResponse.permissions,
          },
        };

        // Set the invited space as selected space.
        selectedSpace = inviteResponse.space;
      } catch (error) {
        auth.logout();
        showError(error.message);
        setIsLoading(false);
        return;
      }
    }

    auth.login(userData);

    // Redirect to collaboration-invite page if collaborationInvite flow after login
    // if (query?.collaborationInvite) {
    //   routeToReturnURL();
    //   return;
    // }

    if (!selectedSpace) {
      throw new Error('Default space is being not set');
    }

    await switchToSpace(selectedSpace.space_id);

    const landingPageRoute = getLandingPageRoute({
      currentSpaceId: selectedSpace.space_id,
      userPermissions: userData?.permissions && { ...userData.permissions },
    });

    router.replace({
      pathname: landingPageRoute,
    });

    setIsLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      email: query?.inviteeEmail || '',
      password: '',
      newPassword: '',
      isPasswordResetRequired: false,
    },
    validationSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      if (isLoading) return;

      setIsLoading(true);

      try {
        // If password reset is required, call the reset password API
        if (values.isPasswordResetRequired) {
          const resetData = {
            email: values.email,
            lastPassword: values.password,
            newPassword: values.newPassword,
          };

          const { data: userData, message } = await resetDefaultPassword(
            resetData
          );
          showSuccess(message);

          handleSuccessfulLogin(userData);
          return;
        }

        // Normal login flow
        const data = {
          email: values.email,
          password: values.password,
        };

        const { data: loginData, isPasswordResetRequired: resetRequired } =
          await login(data);

        if (resetRequired) {
          formik.setFieldValue('isPasswordResetRequired', true);
          setIsLoading(false);
          return;
        }

        handleSuccessfulLogin(loginData);
      } catch (error) {
        showError(error.message);
        setIsLoading(false);
      }
    },
  });

  return (
    <AuthPageWrapper>
      <PageTitle
        pageName={
          formik.values.isPasswordResetRequired ? t`Reset Password` : t`Login`
        }
      />
      <div>
        <div className={classes.cardContainer}>
          <Text mb="lg" size={30} weight={500}>
            {formik.values.isPasswordResetRequired ? (
              <Trans>Reset Your Password</Trans>
            ) : (
              <Trans>Login to D3x</Trans>
            )}
          </Text>

          {formik.values.isPasswordResetRequired && (
            <Text mb="lg" size={14} color="dimmed">
              <Trans>
                Your account requires a password reset. Please enter a new
                password below.
              </Trans>
            </Text>
          )}

          <form onSubmit={formik.handleSubmit}>
            <TextInput
              type="email"
              id="email"
              name="email"
              label={<InputLabel title={t`Email`} />}
              placeholder="name@email.com"
              onChange={formik.handleChange}
              value={formik.values.email}
              error={getError('email', formik).msg}
              disabled={
                !!query?.inviteeEmail || formik.values.isPasswordResetRequired
              }
              required
              mb="sm"
            />

            <PasswordInput
              id="password"
              name="password"
              label={
                <InputLabel
                  title={
                    formik.values.isPasswordResetRequired
                      ? t`Current Password`
                      : t`Password`
                  }
                />
              }
              placeholder={
                formik.values.isPasswordResetRequired
                  ? t`current password`
                  : t`password`
              }
              onChange={formik.handleChange}
              value={formik.values.password}
              error={getError('password', formik).msg}
              disabled={formik.values.isPasswordResetRequired}
              required
              mb="sm"
            />

            {formik.values.isPasswordResetRequired && (
              <PasswordInput
                id="newPassword"
                name="newPassword"
                label={<InputLabel title={t`New Password`} />}
                placeholder={t`new password`}
                onChange={formik.handleChange}
                value={formik.values.newPassword}
                error={getError('newPassword', formik).msg}
                required
                mb="sm"
              />
            )}

            {!formik.values.isPasswordResetRequired && (
              <Text align="right" mb="sm" className={classes.linkButton}>
                <Link
                  href={PAGE_ROUTES.passwordReset}
                >{t`Forgot Password`}</Link>
              </Text>
            )}

            <Button type="submit" loading={isLoading} fullWidth>
              {formik.values.isPasswordResetRequired ? (
                <Trans>Reset Password</Trans>
              ) : (
                <Trans>Login</Trans>
              )}
            </Button>
          </form>

          {!formik.values.isPasswordResetRequired && (
            <Text align="center" size={12} color="dimmed" mt="lg">
              <Trans>{`Don't have an account?`}</Trans>

              <UnstyledButton
                className={classes.linkButton}
                onClick={redirectToSignup}
              >
                <Trans>Signup</Trans>
              </UnstyledButton>
            </Text>
          )}
        </div>
      </div>
    </AuthPageWrapper>
  );
}

Login.getLayout = AuthLayout;
